<template>
  <div style="padding-bottom: 200px">
    <div
      v-if="recurringJobs?.length == 0"
      class="flex justify-end mb-2"
    >
      <Button
        type="button"
        label="New Recurrence"
        icon="pi pi-plus"
        @click="$store.dispatch('setSliderView', 'RecurrenceSlider')"
        class="p-button-text p-button-secondary"
      />
    </div>

    <RecurrenceForm
      v-if="showForm"
      :jobProp="job"
    />

    <div v-if="recurringJobs?.length > 0">
      <div
        class="flex flex-wrap parent-div border-bottom-only pt-3"
        v-for="job in recurringJobs"
        :key="job.uid"
      >
        <i
          class="las la-pen i-20 grey hover-show cursor-pointer"
          style="position:absolute;right:3rem"
          @click="editRecurrence(job)"
        ></i>

        <div class="col-12 sm:col-6 field">
          <span class="font-light">Repeat Every: </span>
          {{ job.repeatIntervalLabel }}
        </div>

        <div class="col-12 sm:col-6 field">
          <span class="font-light">Job Name: </span>
          {{ job.jobName }}
        </div>

        <div class="col-12 sm:col-6 field">
          <span class="font-light">First Occurrence: </span>
          {{ job.firstOccurrence }}
        </div>

        <div class="col-12 sm:col-6 field">
          <span class="font-light">Repeat Until: </span>
          {{ job.repeatUntil }}
        </div>

        <div class="field col-12 align-items-center mb-0">
          <div class="field-checkbox">
            <label
              for="notifyAE"
              style="color: #687a8d !important"
              class="ml-0"
            >Notify Account Executive</label>
            <Checkbox
              id="notifyAE"
              class="ml-2"
              v-model="job.notifyAccountExecutive"
              :binary="true"
            />
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import RecurrenceForm from "../../Job/components/RecurrenceForm.vue";
export default {
  components: {
    RecurrenceForm,
  },
  props: {
    jobProp: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showForm: false,
      submitted: false,
      recurringJobs: [],
      existingRecurrence: null,
      isLoading: false,
    };
  },
  mounted() {
    this.fetchRecurringJobs();
  },
  computed: {
    jobId() {
      return this.jobProp?.uid;
    },
  },
  methods: {
    closeForm() {
      this.showForm = false;
      this.isLoading = false;
    },
    editRecurrence(job) {
      this.existingRecurrence = job;
      this.showForm = true;
    },
    async fetchRecurringJobs() {
      this.isLoading = true;
      try {
        const response = await this.$axios.get(`/v1/jobs/recur/` + this.jobId, {
          headers: { "Content-type": "application/json" },
        });
        const recurrences = response.data;
        if (recurrences?.length > 0) {
          this.recurringJobs = recurrences.map((job) => {
            job.repeatIntervalLabel = job.recur_day + " day of month";
            job.firstOccurrence = new Date(job.recur_next).toDateString();
            job.repeatUntil = new Date(job.recur_until).toDateString();
            job.notifyAccountExecutive = false;
            return job;
          });
          this.existingRecurrence = null;
          if (this.showForm) {
            this.existingRecurrence = recurrences[0];
          }
        } else {
          this.recurringJobs = [];
          this.existingRecurrence = null;
        }
        this.isLoading = false;
      } catch (error) {
        console.error("Failed to fetch recurring jobs:", error);
        this.$toast.add({
          severity: "error",
          detail: "There was an error fetching the data",
          life: 3000,
        });
        this.isLoading = false;
      }
    },
  },
};
</script>
  
<style>
</style>