<template>
  <div style="padding-bottom: 200px">
    <form
      @submit.prevent="submitHandler"
      @keyup.enter="handleKeyPress"
    >

      <div class="formgrid p-fluid grid">
        <!-- Job Name -->
        <div class="field col-6">
          <label for="jobName">Job Name</label>
          <div class="p-inputgroup">
            <InputText
              id="jobName"
              v-model="recurring.jobName"
              placeholder="Job Name"
            />
          </div>
          <div
            v-if="submitted && !recurring.jobName"
            class="error-validation-text pt-1"
          >
            Job name is required
          </div>
        </div>
        <div class="field col-6">
          <div class="p-inputgroup pt-4">

            <div class="font-light font-smaller">
              <span style="font-weight:600">Use dynamic naming variables<br /></span>

              include {month}, {year}, or {client} variables in the Job name for auto period naming.
            </div>
          </div>
        </div>

        <!-- Job Template -->
        <div class="field col-6">
          <label for="jobTemplate">Use Job Template</label>
          <div class="p-inputgroup">
            <Dropdown
              id="jobTemplate"
              :options="jobTemplates"
              optionLabel="title"
              optionValue="uid"
              v-model="recurring.jobTemplate"
              placeholder="Select Template"
            />
          </div>
          <div
            v-if="submitted && !recurring.jobTemplate"
            class="error-validation-text pt-1"
          >
            Job template is required
          </div>
        </div>
        <div class="field col-6">
          <div class="p-inputgroup">

            <div class="font-light font-smaller pt-2">
              <span style="font-weight:600">Examples:</span>

              <li class="pt-2 pl-2"> {client} {month+1} Retainer</li>
              <li class="pt-2 pl-2">ANC {year} Hosting</li>
              <li class="pt-2 pl-2">{month} Content Calendar</li>

            </div>
          </div>
        </div>

        <div class="field col-6">
          <label for="client">Client</label>
          <div class="p-inputgroup">
            <Dropdown
              id="client"
              :options="clients"
              optionLabel="name"
              optionValue="uid"
              v-model="recurring.client"
              placeholder="Select Client"
            />
          </div>
          <div
            v-if="submitted && !recurring.client"
            class="error-validation-text pt-1"
          >
            Client is required
          </div>
        </div>
        <div class="field col-6"></div>
        <!-- Repeat Every -->
        <div class="field col-6">
          <label for="repeatEvery">Repeat Every</label>
          <div class="p-inputgroup">
            <InputNumber
              id="repeatEvery"
              v-model="recurring.repeatValue"
              min="1"
              max="12"
              class="w-1"
            />
            <Dropdown
              :options="repeatIntervals"
              v-model="recurring.repeatInterval"
              optionLabel="label"
              optionValue="value"
              class="w-8"
              placeholder="Interval"
            />
          </div>
          <div
            v-if="submitted && !recurring.repeatInterval"
            class="error-validation-text pt-1"
          >
            Interval is required
          </div>
        </div>

        <!-- Notify Account Executive -->
        <div class="field col-6 align-content-center  align-items-center pt-5">
          <div class="field-checkbox">
            <Checkbox
              id="notifyAE"
              v-model="recurring.notifyAccountExecutive"
              :binary="true"
            />
            <label
              for="notifyAE"
              class="ml-2"
            >Notify Account Executive</label>
          </div>
        </div>
        <!-- Ends Section -->
        <div class="field col-6 align-content-center">
          <label>Ends</label>
          <div class="field-checkbox pt-2">

            <Checkbox
              inputId="never"
              value="never"
              v-model="recurring.endOption"
            />
            <label for="never">Never</label>

          </div>
        </div>
        <div class="col-6"></div>
        <div class="field col-6 align-content-center">
          <div
            class="field-checkbox"
            style="height:40px"
          >
            <Checkbox
              inputId="on"
              value="on"
              v-model="recurring.endOption"
            />
            <label for="on">On</label>
            <Calendar
              v-model="recurring.endDate"
              v-if="recurring.endOption.includes('on')"
              :showIcon="true"
              placeholder="Select Date"
              class="ml-3"
            />
          </div>
        </div>
        <div class="col-6"></div>
        <div class="field col-6 align-content-center">
          <div
            class="field-checkbox"
            style="height:40px"
          >
            <Checkbox
              inputId="after"
              value="after"
              v-model="recurring.endOption"
            />

            <label for="after">After</label>
            <InputNumber
              v-model="recurring.endOccurrences"
              class=" ml-3 input-w-50"
              min="1"
              v-if="recurring.endOption.includes('after')"
            />

            <label
              class="ml-2"
              v-if="recurring.endOption.includes('after')"
            >Occurrences</label>
          </div>

        </div>
      </div>

      <div class="field col-12"></div>

      <!-- Buttons -->
      <div class="flex justify-content-between mt-4">
        <Button
          type="submit"
          label="Save"
        />
        <Button
          type="button"
          @click="clearSliderContent"
          label="Cancel"
          class="p-button-text p-button-secondary"
        />
      </div>
    </form>
  </div>
</template>
<script>
import {
  fetchClientsSummary,
  fetchTemplates,
  state,
} from "../../../services/data_service";

export default {
  name: "RecurringForm",
  async mounted() {
    await Promise.all([fetchClientsSummary(), fetchTemplates()]);

    if (this.jobProp) {
      this.recurring = this.jobProp;
    }

    this.$store.dispatch("setSliderLoader", false);
  },
  props: {
    jobProp: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      submitted: false,
      active: true,
      recurring: {
        jobName: "",
        jobTemplate: null,
        client: null,
        repeatValue: 1,
        repeatInterval: null,
        endOption: ["never"],
        endDate: null,
        endOccurrences: 1,
        notifyAccountExecutive: false,
      },
      repeatIntervals: [
        { label: "Day(s)", value: "days" },
        { label: "Week(s)", value: "weeks" },
        { label: "Month(s)", value: "months" },
        { label: "Year(s)", value: "years" },
      ],
    };
  },
  computed: {
    clients() {
      return state.clientsSummary;
    },
    jobTemplates() {
      return state.templates;
    },
  },
  methods: {
    handleKeyPress(event) {
      const editor = this.$refs.editor;
      if (editor && editor.$el.contains(event.target)) {
        // If "Enter" is pressed inside the editor, create a new line
        event.stopPropagation();
      } else {
        // Else, submit the form
        this.submitHandler();
      }
    },
    async submitHandler() {
      this.submitted = true;
      if (
        !this.recurring.jobName ||
        !this.recurring.jobTemplate ||
        !this.recurring.repeatInterval ||
        !this.recurring.client
      ) {
        return;
      }
      this.$store.dispatch("setSliderLoader", true);
      const payload = {
        job_name: this.recurring.jobName,
        job_uid: this.recurring.jobTemplate,
        client_uid: this.recurring.client,
        recur_day: new Date().getDate(), // get current day, or create a field to select day of the month
        recur_next: new Date().toISOString().slice(0, 10),
        recur_until: this.getRecurUntil(),
      };

      try {
        const response = await this.$axios.post("/v1/jobs/recur", payload, {
          headers: { "Content-type": "application/json" },
        });
        console.log("Recurring job created successfully:", response.data);
        this.$toast.add({
          severity: "success",
          detail: "Recurring Job Created",
          life: 3000,
        });
      } catch (error) {
        console.error("Failed to create recurring job:", error);
        this.$toast.add({
          severity: "error",
          detail: "There was an error saving the job",
          life: 3000,
        });
      } finally {
        this.$store.dispatch("setSliderLoader", false);
      }
    },
    getRecurUntil() {
      if (this.recurring.endOption.includes("never")) {
        return "2099-12-31";
      }
      if (this.recurring.endOption.includes("on")) {
        return this.recurring.endDate.toISOString().slice(0, 10);
      }
      if (this.recurring.endOption.includes("after")) {
        const currentDate = new Date();
        let recurUntil = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          currentDate.getDate() + this.recurring.endOccurrences * 30
        );
        return recurUntil.toISOString().slice(0, 10);
      }
    },
  },
  watch: {
    "recurring.endOption": function (val) {
      if (val?.length > 1) {
        this.recurring.endOption = [val[val.length - 1]];
      }
    },
  },
};
</script>
  
  <style scoped>
</style>